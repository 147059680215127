import { useState, useEffect, useRef, useCallback } from 'react';
import { Tooltip } from 'antd';
import './SignKitColorSelector.css';
import { BlockPicker } from 'react-color';
import { IoColorPaletteOutline } from "react-icons/io5";
import { IServiceURL } from "../../interfaces/serviceURL.interface";
import { findPriceChangeURL } from "../../utils/utils";
import appConfig from "../../config/config.json";
import { getSignKitColors } from "../../utils/api/pricechange.service";
import { updateSignKitColors } from "../../utils/api/pricechange.service";
import { getEndpoints } from "../../utils/api/config.service";
import { useSelector } from 'react-redux';
import { RootState } from '../../reduxStore/reducers/rootReducer';
import { useOktaAuth } from '@okta/okta-react';

const SignKitColorSelector = () => {
    const { authState }= useOktaAuth();
    const readOnlyState = useSelector(
        (state: RootState) => state.readOnly
    )

    const [doorbusterSignKitColor, setDoorbusterSignKitColor] = useState('#ffffff');
    const [pdfSignKitColor, setPdfSignKitColor] = useState('#ffffff');
    const [genericSignKitColor, setGenericSignKitColor] = useState('#ffffff');
    const [genericAlternaticeSignKitColor, setGenericAlternaticeSignKitColor] = useState('#ffffff');
    const [showColorMenu, setShowColorMenu] = useState(false);
    const [serviceURLs, setServiceURLs] = useState<IServiceURL[]>([]);
    const colorMenuRef = useRef(null);

    const handleDoorbusterColorSelection = (color) => {
        updateSignKitColorHandler("Doorbuster Kit", color.hex);
        setDoorbusterSignKitColor(color.hex);
    };
    const handlePdfColorSelection = (color) => {
        updateSignKitColorHandler("PDF", color.hex);
        setPdfSignKitColor(color.hex);
    };
    const handleGenericColorSelection = (color) => {
        updateSignKitColorHandler("Generic", color.hex);
        setGenericSignKitColor(color.hex);
    };
    const handleGenericAlternativeColorSelection = (color) => {
        updateSignKitColorHandler("Generic Alt Color", color.hex);
        setGenericAlternaticeSignKitColor(color.hex);
    };

    const fetchServiceURLs = async () => {
        try {
            const response = await getEndpoints() as any;
            const data = await JSON.parse(response);

            setServiceURLs(data["endpoints"]);
        } catch (err) {
            console.log(err);
        }
    };

    const updateSignKitColorHandler = async (signKit: String, signKitColor: String) => {
        try {
            const priceChangeAPI = findPriceChangeURL(serviceURLs, appConfig.stage); // TODO: to pull env specific when CI is integrated.
            if (!priceChangeAPI) {
                throw new Error("Could not find Price Change API.")
            }
            const body = {
                "sign_kit_color": signKitColor
            }
            await updateSignKitColors(priceChangeAPI!, body, signKit);

        } catch (err: unknown) {
            console.log(err);
        }
    }

    const fetchSignKitColors = useCallback(async (serviceURLs: IServiceURL[]) => {
        if (!serviceURLs.length) return;

        // Fetch sign kit colors
        try {
            const priceChangeAPI = findPriceChangeURL(serviceURLs, appConfig.stage); // TODO: to pull env specific when CI is integrated.
            if (!priceChangeAPI) {
                throw new Error("Could not find Price Change API.")
            }

            const response = await getSignKitColors(priceChangeAPI) as any;
            const data = await response.json();

            data.forEach(store => {
                if(store.name === "PDF") {
                    setPdfSignKitColor(store.color)
                }
                if(store.name === "Generic") {
                    setGenericSignKitColor(store.color)
                }
                if(store.name === "Generic Alt Color") {
                    setGenericAlternaticeSignKitColor(store.color)
                }
                if(store.name === "Doorbuster Kit") {
                    setDoorbusterSignKitColor(store.color)
                }

            });
        } catch (err) {
            console.log(err);
        }
    }, []);

    // determines if color label on read-only text is white or black
    const getColorPickerText = (color) => {
        color = color.replace(/^#/, '')
        // If the color code is 3 characters, expand it to 6 characters
        if (color.length === 3) {
            color = color.split('').map(char => char + char).join('');
        }

        // Ensure the color code is now exactly 6 characters
        if (color.length !== 6) {
            throw new Error('Invalid color code');
        }

        // Extract the red, green, and blue components
        const red = Number(`0x${color.substring(0, 2)}`);
        const green = Number(`0x${color.substring(2, 4)}`);
        const blue = Number(`0x${color.substring(4, 6)}`);

        const colorBrightness = red*0.299 + green*0.587 + blue*0.114;

        if ((colorBrightness) > 130) {
            return "#000000"
        } else {
            return "#FFFFFF"
        }
    }

    const readOnlyOverlay = (color) => readOnlyState.isReadOnly && 
            ( <div className="overlay" style={{backgroundColor: color, color: getColorPickerText(color)}}>
                {color}
              </div>
            ) 

    useEffect(() => {
        fetchServiceURLs();
    }, [authState]);

    useEffect(() => {
        if (serviceURLs.length > 0) {
            fetchSignKitColors(serviceURLs);
        }
    }, [fetchSignKitColors, serviceURLs])

    return (
        <div className='color-container'>
           <Tooltip placement="left" title={"Select Sign Kit Colors"}>
              <button onClick={() => setShowColorMenu(prevState => !prevState)} className='sign-kit-button'>
                  <IoColorPaletteOutline size={30} color={"white"} />
              </button>
            </Tooltip>
            {showColorMenu && (
                <div className='color-menu' ref={colorMenuRef}>
                    <div className='color-container-inner'>
                        <span className='color-text-display'>Doorbuster Sign Kit Color </span>
                        <div className='color-menu-rows'>
                            <BlockPicker colors={['#008552', '#EE3324']} color={doorbusterSignKitColor} onChange={handleDoorbusterColorSelection} />
                            { readOnlyOverlay(doorbusterSignKitColor) }
                        </div>
                    </div>
                    <div className='color-container-inner'>
                        <span className='color-text-display'>Pdf Sign Kit Color </span>
                        <div className='color-menu-rows'>
                            <BlockPicker colors={['#004899', '#8d8d8d', '#EE3324']} color={pdfSignKitColor} onChange={handlePdfColorSelection} />
                            { readOnlyOverlay(pdfSignKitColor) }
                        </div>
                    </div>
                    <div className='color-container-inner'>
                        <span className='color-text-display'>Generic Sign Kit Color </span>
                        <div className='color-menu-rows'>
                            <BlockPicker colors={['#8d8d8d', '#EE3324']} color={genericSignKitColor} onChange={handleGenericColorSelection} />
                            { readOnlyOverlay(genericSignKitColor) }
                        </div>
                    </div>
                    <div className='color-container-inner'>
                        <span className='color-text-display'>Generic Alternative Sign Kit Color </span>
                        <div className='color-menu-rows'>
                            <BlockPicker colors={['#8d8d8d', '#32CD32']} color={genericAlternaticeSignKitColor} onChange={handleGenericAlternativeColorSelection} />
                            { readOnlyOverlay(genericAlternaticeSignKitColor) }
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SignKitColorSelector;
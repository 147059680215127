import { FC } from "react";
import { Col, Row, Spin } from "antd";

const Spinner: FC<{}> = () => {
  return (
    <Row align="middle" justify="center" style={{ minHeight: "100vh" }}>
      <Col>
        <Spin/>
      </Col>
    </Row>
  );
};

export default Spinner;

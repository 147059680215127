import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../reduxStore/reducers/rootReducer';
import { setReadOnly, setReadWrite } from "../../reduxStore/reducers/readOnlySlice";
import { ConfigProvider, Space, Switch } from "antd";

const ReadOnlyToggle = () => {

  const readOnlyState = useSelector(
    (state: RootState) => state.readOnly
  );

  const dispatch = useDispatch<any>();

  const theme={
                components: {
                  Switch: {
                    handleSize: 22,
                    trackPadding: 0
                  }
                }
              }

    const switchClickHandler = () => {
      readOnlyState.isReadOnly ? dispatch(setReadWrite()) : dispatch(setReadOnly());
    }

    return (
      <ConfigProvider theme={theme}>
        <Space>
          <Switch onClick={switchClickHandler} value={readOnlyState.isReadOnly}></Switch>
          <span style={{ color: '#F8F8FF'}}>{readOnlyState.isReadOnly ? "Read-Only" : "Read-Write"}</span>
        </Space>
      </ConfigProvider>
    )
};

export default ReadOnlyToggle;
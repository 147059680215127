import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import appConfig from "../../config/config.json";
import { AppThunk } from "../reduxStore";
import { store } from "../../interfaces/storeInterface";

interface getReadOnlyState {
  isReadOnly: boolean;
  isLoading: boolean;
  error: string | null;
}

const initialState: getReadOnlyState = {
  isReadOnly: false,
  isLoading: false,
  error: null,
};

const ReadOnlySlice = createSlice({
  name: "stores",
  initialState,
  reducers: {
    getReadOnlyStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getReadOnlySuccess(state, action: PayloadAction<any>) {
      state.isReadOnly = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    getReadOnlyFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.isReadOnly = true;
      state.error = action.payload;
    },
    updateReadOnlyStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    updateReadOnlySuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    updateReadOnlyFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.isReadOnly = true;
      state.error = action.payload;
    },
    setReadOnlyState(state, action: PayloadAction<any>) {
      state.isReadOnly = action.payload;
    }
  },
});

export default ReadOnlySlice.reducer;
const {
  updateReadOnlyStart,
  setReadOnlyState,
  updateReadOnlySuccess,
  updateReadOnlyFailed
} = ReadOnlySlice.actions;

export const setReadOnly = (): AppThunk => async (dispatch) => {
  try {
    dispatch(updateReadOnlyStart());
    dispatch(setReadOnlyState(true));
    dispatch(updateReadOnlySuccess());
  } catch (error) {
    console.log("ERROR setting Read Only State: ", error);
    dispatch(updateReadOnlyFailed(error));
  }
};

export const setReadWrite = (): AppThunk => async (dispatch) => {
  try {
    dispatch(updateReadOnlyStart());
    dispatch(setReadOnlyState(false));
    dispatch(updateReadOnlySuccess());
  } catch (error) {
    console.log("ERROR setting Read Only State: ", error);
    dispatch(updateReadOnlyFailed(error));
  }
};
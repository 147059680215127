// This file is used to combine reducers, if added a new slice, once the reducer is exported
// it needs to be added to the combine reducers function
import { combineReducers, createAction, PayloadAction } from "@reduxjs/toolkit";
import storesSlice from "./storesSlice";
import priceChangesSlice from "./priceChangesSlice";
import serviceURLsSlice from "./serviceURLsSlice";
import promotionsSlice from "./promotionsSlice";
import readOnlySlice from "./readOnlySlice";
import signKitColorsSlice from "./signKitColorsSlice"; 

// Combine reducers into one
const reducer = combineReducers({
  stores: storesSlice,
  serviceURLs: serviceURLsSlice,
  priceChanges: priceChangesSlice,
  promotions: promotionsSlice,
  readOnly: readOnlySlice,
  signKitColors: signKitColorsSlice,
});

export const resetAction = createAction("logoutClearStore");

const rootReducer = (state: any, action: PayloadAction<any>) => {
  if (resetAction.match(action)) {
    return reducer(undefined, action);
  }
  return reducer(state, action);
};

// type declaration we can reuse
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

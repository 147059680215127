import { Alert, Button, Modal, Spin, Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { getEndpoints } from '../../utils/api/config.service';
import appConfig from "../../config/config.json";
import { findPriceChangeURL } from '../../utils/utils';
import { getPriceChanges } from '../../utils/api/pricechange.service';
import jsPDF, { TextOptionsLight } from 'jspdf';
import { IPriceChange } from '../../interfaces/priceChange.interface';
import { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

/** 
 * Component which exports price change data to PDF.
 * Takes input as effective date and selected store, selected within the header of the application.
*/
const ExportToPdf = ({ store, effectiveDate }: { store: string, effectiveDate: Date }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalCloseable, setIsModalCloseable] = useState(false);

  //Executes on the event when "Export to PDF" button-click.
  const initExport = async () => {
    setIsModalOpen(true);
    setIsLoading(true);
    setErrorMessage("");
    setIsModalCloseable(false);

    const success = await handleExport();
    if (success) {
      setIsModalOpen(false);
      setIsLoading(false);
    } else {
      setErrorMessage("Unable to export, please try again later.");
      setIsLoading(false);
      setIsModalCloseable(true);

    }
  }

  //Compiles pdf document based on data
  const handleExport = async () => {

    const endpointsResponse = await getEndpoints() as any;
    const endpointsData = await JSON.parse(endpointsResponse);
    const priceChangeEndpoint = findPriceChangeURL(endpointsData["endpoints"], appConfig.stage);
    if (!priceChangeEndpoint)
      return false;

    //Fetch pricing data.  
    const priceChangeResponse = await getPriceChanges(priceChangeEndpoint, store, effectiveDate) as any;
    const priceData: IPriceChange[] = await priceChangeResponse.json();

    // Define pdf document and table parameters.
    const doc = new jsPDF('portrait', 'pt', 'a4');
    const startX = 15; //initial x-axis
    const startY = 5;  //initial y-axis
    const rowHeight = 50;
    const colWidth = 60;
    const headerRowHeight = 20;
    const maxTextWidth = 50; // Max width for wrapping text
    const availableHeight = doc.internal.pageSize.getHeight() - startY;
    const maxRowsPerPage = Math.floor((availableHeight - headerRowHeight) / rowHeight) - 1;
    const font = "helvetica";

    //Set initial values
    let rowIndex = 0;
    let currPage = 1;

    //Start compiling document from here..
    while (rowIndex < priceData.length) {

      if (currPage > 1) {
        doc.addPage();//add next page to document.
      }

      //Set headers on each page with following parameters.
      const setHeaderYAlign = startY + headerRowHeight / 2;
      doc.setFont(font, 'bold');
      doc.setFontSize(7);
      doc.text('Brand', startX, setHeaderYAlign);                         //col1
      doc.text('SBU', startX + colWidth, setHeaderYAlign);                //col2
      doc.text('Department', startX + colWidth * 2, setHeaderYAlign);     //col3
      doc.text('Collection', startX + colWidth * 3, setHeaderYAlign);     //col4
      doc.text('Price\nChanged', startX + colWidth * 4, setHeaderYAlign); //col5
      doc.text('Promo Price', startX + colWidth * 5, setHeaderYAlign);    //col6
      doc.text('Sign Kit', startX + colWidth * 6, setHeaderYAlign);       //col7
      doc.text('PDF\nSign', startX + colWidth * 7, setHeaderYAlign);      //col8
      doc.text('Comments', startX + colWidth * 8, setHeaderYAlign);       //col9
      //Header ends.
      doc.line(startX, startY + headerRowHeight, startX + colWidth * 9, startY + headerRowHeight);

      //Set parameters for data cells
      doc.setFont(font, 'normal');

      //compile rows for a page.. 
      for (let rowOnPage = 0; rowOnPage < maxRowsPerPage && rowIndex < priceData.length; rowOnPage++) {
        const rowData = priceData[rowIndex];
        const align: TextOptionsLight = { align: 'left' };
        const setCellYalign = startY + headerRowHeight + (rowOnPage + 1) * rowHeight;

        //add cells for a row
        doc.text(doc.splitTextToSize(rowData["brand"] || "", maxTextWidth), startX, setCellYalign, align);
        doc.text(doc.splitTextToSize(rowData["category"].toString() || "", maxTextWidth), startX + colWidth, setCellYalign, align);
        doc.text(doc.splitTextToSize(rowData["department"].toString() || "", maxTextWidth), startX + colWidth * 2, setCellYalign, align);
        doc.text(doc.splitTextToSize(rowData["collection"].toString() || "", maxTextWidth), startX + colWidth * 3, setCellYalign, align);
        doc.text(doc.splitTextToSize((rowData["price_change"] || false).toString(), maxTextWidth), startX + colWidth * 4, setCellYalign, align);
        doc.text(doc.splitTextToSize(rowData["promo_price"].toString() || "", maxTextWidth), startX + colWidth * 5, setCellYalign, align);
        doc.text(doc.splitTextToSize(rowData["sign_kit"].toString() || "", maxTextWidth), startX + colWidth * 6, setCellYalign, align);
        doc.text(doc.splitTextToSize((rowData["is_pdf"] || false).toString(), maxTextWidth), startX + colWidth * 7, setCellYalign, align);
        doc.text(doc.splitTextToSize(rowData["comments"].toString() || "", maxTextWidth), startX + colWidth * 8, setCellYalign, align);
        rowIndex++;
      }
      //Add Page number
      doc.text(`Page ${currPage}`, startX, startY + availableHeight - 20);
      currPage++;

    }//Compiling of document ends here

    doc.output('dataurlnewwindow', {filename: store ? `${store}-${new Date(effectiveDate).toLocaleDateString()}` : `Generic-${new Date(effectiveDate).toLocaleDateString()}`});
    return true;

  }


  return (
    <div style={{ display: 'flex' }}>
      <Tooltip placement="left" title={"Download PDF"}>
        <Button icon={<DownloadOutlined style={{color:'white', fontSize: 25 }}/>} onClick={initExport} style={{background: 'none',border: 'none'}}></Button>
      </Tooltip>
      <Modal title="Exporting Please Wait..."
        open={isModalOpen}
        onOk={() => { setIsModalOpen(false) }}
        onCancel={() => { setIsModalOpen(false) }}
        closable={isModalCloseable}
        keyboard={false}
        maskClosable={false}
        footer={null}>
        {isLoading && (<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />)}
        {errorMessage && (<Alert message={errorMessage} type="error" />)}
      </Modal>
    </div>
  );
};

export default ExportToPdf;

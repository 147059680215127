import { AutoComplete, Input } from 'antd';
import React, { useState, useEffect } from 'react';
import { store } from '../../interfaces/storeInterface';
const Stores: React.FC<{ stores: store[], handleStoreState: any }> = (storesProp) => {
  const [storeNumber, setStoreNumber] = useState<string>("");
  let [options, setOptions] = useState<any | null>(null);
  useEffect(() => {
    let storeOptions: { label: string; value: string; key: string }[] = [];
    storesProp.stores.map((store) => (
      storeOptions.push({ "label": store.storeNumber, "value": store.storeNumber, "key": store.storeNumber })
    ));
    setOptions(storeOptions);

  }, [storesProp.stores]);

  const onSelect = async (value: string) => {
    await storesProp.handleStoreState(value);
    setStoreNumber(value);
  };

  const handleSearch = async (value: string) => {
    await storesProp.handleStoreState(value);
    setStoreNumber(value);
  };

  const clearStore = async () => {
    console.log('store cleared');
    await storesProp.handleStoreState("");
    setStoreNumber("");
  };

  return (
    <AutoComplete
      placeholder={"Chain (Select Store)"}
      showSearch={true}
      allowClear={true}
      optionFilterProp="children"
      style={{ float: "right", width: "150px", marginRight: "50px" }}
      value={storeNumber}
      options={options}
      onSelect={onSelect}
      onClear={clearStore}
      onSearch={handleSearch}
      notFoundContent={"No stores found"}
      filterOption={(input, option) => {
        return (
          option!.key?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      }}
    >
      <Input.Search enterButton style={{width: "190px"}}/>
    </AutoComplete>
  );
};

export default Stores;

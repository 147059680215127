import { configureStore, Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import rootReducer, { RootState } from "./reducers/rootReducer";

  // Initial store setup
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    devTools: process.env.NODE_ENV !== "development" ? false : true,
  });

  //  hot-reloading the root reducer
  if (process.env.NODE_ENV === "development" && module.hot) {
    module.hot.accept("./reducers/rootReducer", () => {
      const newRootReducer = require("./reducers/rootReducer").default;
      store.replaceReducer(newRootReducer);
    });
  }
  // type declaration we can reuse
  export type AppDispatch = typeof store.dispatch;
  export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
  
  export default store;
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import appConfig from "../../config/config.json";
import { AppThunk } from "../reduxStore";
import { store } from "../../interfaces/storeInterface";

interface getStoresState {
  stores: store[];
  isLoading: boolean;
  error: string | null;
}

const initialState: getStoresState = {
  stores: [],
  isLoading: false,
  error: null,
};

const StoresSlice = createSlice({
  name: "stores",
  initialState,
  reducers: {
    getStoreStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getStoreDetailsSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.stores = action.payload;
      state.error = null;
    },
    getStoreDetailsFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.stores = [];
      state.error = action.payload;
    },
  },
});

export default StoresSlice.reducer;
const {
  getStoreStart,
  getStoreDetailsSuccess,
  getStoreDetailsFailed,
} = StoresSlice.actions;

export const fetchStores = (): AppThunk => async (dispatch) => {
  const tokenStorageRaw = localStorage.getItem("okta-token-storage");
  if (tokenStorageRaw) {
    try {
      dispatch(getStoreStart());
      const tokenStorage = JSON.parse(tokenStorageRaw);
      const headers = {
        "Authorization": tokenStorage?.accessToken?.accessToken || ""
      }

      const response = await fetch(appConfig.stores, { headers });
      if (response.ok) {
        const data = await response.json();
        dispatch(getStoreDetailsSuccess(data.stores));
      }

    } catch (error) {
      console.log("ERROR fetching stores: ", error);
      dispatch(getStoreDetailsFailed(error));
    }
  }
}

import appConfig from "../../config/config.json";
import { endPointskey } from "../../config/enviornment";

//todo refactor to not refetch 
const key = endPointskey;
const getEndpoints = async () => {
    try {
        const localData = localStorage.getItem(key);
        const tokenStorageRaw = localStorage.getItem("okta-token-storage");
        if ((localData === null || localData === undefined) && tokenStorageRaw) {
            const tokenStorage = JSON.parse(tokenStorageRaw);
            const headers = {
                "Authorization": tokenStorage?.accessToken?.accessToken || ""
            }
            const response = await fetch(appConfig.endpoints, { headers });
            if (!response.ok) {
                console.log(response.statusText);
                throw new Error(response.statusText);
            }
            const data = await response.json();
            localStorage.setItem(key, JSON.stringify(data));
            return JSON.stringify(data);
        } else {
            return localData;
        }

    } catch (err) {
        console.log(`Unable to fetch endpoints. ERR: ${err}`)
    }
}
export {
    getEndpoints
}
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../reduxStore";
import { getEndpoints } from "../../utils/api/config.service";
import { IServiceURL } from "../../interfaces/serviceURL.interface";

interface getServiceUrlsState {
  data: IServiceURL[];
  isLoading: boolean;
  error: string | null;
}

const initialState: getServiceUrlsState = {
  data: [],
  isLoading: false,
  error: null,
};

const PriceChangesSlice = createSlice({
  name: "serviceURLs",
  initialState,
  reducers: {
    getServiceURLsStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getServiceURLsDetailsSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    getServiceURLsDetailsFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = [];
      state.error = action.payload;
    },
  },
});

export default PriceChangesSlice.reducer;

const {
    getServiceURLsStart,
    getServiceURLsDetailsSuccess,
    getServiceURLsDetailsFailed,
} = PriceChangesSlice.actions;

export const fetchServiceURLs = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getServiceURLsStart());

    // Fetch service URLs
    const response = await getEndpoints() as any;
    const data = await JSON.parse(response);

    if (data.ok) {
      dispatch(getServiceURLsDetailsSuccess(data["endpoints"]));
    }
  } catch (error) {
    console.log("ERROR fetching service URLs: ", error);
    dispatch(getServiceURLsDetailsFailed(error));
  }
};
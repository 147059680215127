import { useEffect, useRef, useState } from "react"

const StoresCell = ({ stores } : { stores: string[] }) => {
    let style: React.CSSProperties = {
        overflowY: "scroll",
        height: "8em"
    }

    if (stores.length <= 5) style = {};

    return (
        <div style={style}>{stores.join(" ")}</div>
    )
}

export {
    StoresCell
}
import { IPath, IServiceURL } from "../interfaces/serviceURL.interface";

const findPriceChangeURL = (serviceURLs: IServiceURL[], environment: string) => {
    return serviceURLs.find(api => api.name === 'pricechanges')
               ?.paths.find(path => path.environment === environment)
}

const formatDateForAPI = (date: Date): string => {
  const dayjs = require('dayjs');
  const stringDate = dayjs(date).format('YYYY-MM-DD');

  console.log(stringDate);
  return stringDate;
}

export {
    findPriceChangeURL,
    formatDateForAPI
}
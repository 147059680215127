import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import appConfig from "../../config/config.json";
import { AppThunk } from "../reduxStore";
import { findPriceChangeURL } from "../../utils/utils";
import { getPriceChanges, updatePriceChange } from "../../utils/api/pricechange.service";
import { IPriceChange } from "../../interfaces/priceChange.interface";
import { IServiceURL } from "../../interfaces/serviceURL.interface";

interface getPriceChangesState {
  data: IPriceChange[];
  isLoading: boolean;
  error: string | null;
}

const initialState: getPriceChangesState = {
  data: [],
  isLoading: false,
  error: null,
};

const PriceChangesSlice = createSlice({
  name: "priceChanges",
  initialState,
  reducers: {
    getPriceChangesStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getPriceChangesDetailsSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    getPriceChangesDetailsFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = [];
      state.error = action.payload;
    },
    updatePriceChangesStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    updatePriceChangesDetailsSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    updatePriceChangesDetailsFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = [];
      state.error = action.payload;
    },
    togglePDFSign(state, action: PayloadAction<any>) {
      state.data = state.data.map(priceChange => {
        if(priceChange.id === action.payload) {
          return {
            ...priceChange,
            is_pdf: !priceChange.is_pdf
          }
        } else {
          return priceChange
        }
      });
    },
    toggleAltColorSign(state, action: PayloadAction<any>) {
      state.data = state.data.map(priceChange => {
        if(priceChange.id === action.payload) {
          return {
            ...priceChange,
            is_alt_color: !priceChange.is_alt_color
          }
        } else {
          return priceChange
        }
      });
    },
    updateComments(state, action: PayloadAction<any>) {
      state.data = state.data.map(priceChange => {
        if(priceChange.id === action.payload.id) {
          return {
            ...priceChange,
            comments: action.payload.comments
          }
        } else {
          return priceChange
        }
      });
    },

  },
});

export default PriceChangesSlice.reducer;

const {
    getPriceChangesStart,
    getPriceChangesDetailsSuccess,
    getPriceChangesDetailsFailed,
    updatePriceChangesDetailsSuccess,
    updatePriceChangesDetailsFailed,
    togglePDFSign,
    toggleAltColorSign,
    updateComments
} = PriceChangesSlice.actions;

export const fetchPriceChanges = (priceChangesStoreParam: string, serviceURLs: IServiceURL[], effectiveDate: Date): AppThunk => async (dispatch) => {
  try {
    dispatch(getPriceChangesStart());
    
    if (!serviceURLs.length) return;

    // Fetch price changes from backend
    const priceChangeAPI = findPriceChangeURL(serviceURLs, appConfig.stage); // TODO: to pull env specific when CI is integrated.
    if (!priceChangeAPI) {
    throw new Error("Could not find Price Change API.")
    }
    const response = await getPriceChanges(priceChangeAPI, priceChangesStoreParam, effectiveDate) as any;
    const data = await response.json();

    if (data) {
      dispatch(getPriceChangesDetailsSuccess(data));
    }
  } catch (error) {
    console.log("ERROR fetching pricechanges: ", error);
    dispatch(getPriceChangesDetailsFailed(error));
  }
};

export const togglePDFSignAndSync = (
  priceChangeId: Number,
  serviceURLs: IServiceURL[],
  stores: string[],
  isPdf: Boolean,
  isAltColor: Boolean,
): AppThunk => async (dispatch) => {
  try {
    // Make API call to update the database
    const priceChangeAPI = findPriceChangeURL(serviceURLs,  appConfig.stage); // TODO: to pull env specific when CI is integrated.
    
    let storeNums = stores[0] ? stores : ["9999"];
    
    const body = {
        "is_pdf": !isPdf,
        "stores": storeNums
    }

    await updatePriceChange(priceChangeAPI!, body, priceChangeId);

    // Dispatch an action to update Redux store with the new item
    dispatch(togglePDFSign(priceChangeId))
    dispatch(updatePriceChangesDetailsSuccess());
  } catch (error) {
    console.error('Error updating PDF sign:', error);
    // Dispatch an action to handle errors if needed
    dispatch(updatePriceChangesDetailsFailed(error));
  }
};

export const toggleGenericAltAndSync = (
  priceChangeId: Number,
  serviceURLs: IServiceURL[],
  stores: string[],
  isAltColor: Boolean,
): AppThunk => async (dispatch) => {
  try {
    // Make API call to update the database
    const priceChangeAPI = findPriceChangeURL(serviceURLs,  appConfig.stage); // TODO: to pull env specific when CI is integrated.

    let storeNums = stores[0] ? stores : ["9999"];
    const body = {
        "is_alt_color": !isAltColor,
        "stores": storeNums
    }

    await updatePriceChange(priceChangeAPI!, body, priceChangeId);

    // Dispatch an action to update Redux store with the new item
    dispatch(toggleAltColorSign(priceChangeId))
    dispatch(updatePriceChangesDetailsSuccess());
  } catch (error) {
    console.error('Error updating Generic Alt sign:', error);
    // Dispatch an action to handle errors if needed
    dispatch(updatePriceChangesDetailsFailed(error));
  }
};

export const updateCommentAndSync = (
  priceChangeId: Number,
  serviceURLs: IServiceURL[],
  stores: string[],
  comments: string
): AppThunk => async (dispatch) => {
  try {
    const priceChangeAPI = findPriceChangeURL(serviceURLs,  appConfig.stage); // TODO: to pull env specific when CI is integrated.
    
    let storeNums = stores[0] ? stores : ["9999"];
    const body = {
        "comments": comments,
        "stores": storeNums
    }
    await updatePriceChange(priceChangeAPI!, body, priceChangeId);

    // Dispatch an action to update Redux store with the new item
    dispatch(updateComments({ id: priceChangeId, comments: comments }))
    dispatch(updatePriceChangesDetailsSuccess());
  } catch (error) {
    console.error('Error updating comments:', error);
    dispatch(updatePriceChangesDetailsFailed(error));
  }
}
import React, { BaseSyntheticEvent, KeyboardEvent, useState } from 'react';
import 'antd/dist/antd'
import { Button } from 'antd';
import { EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux';
import { RootState } from '../../../reduxStore/reducers/rootReducer';

const Comments = ({ priceChangeId,
                    comments,
                    commentsSubmitHandler,
                    stores,
                  } : {
                    priceChangeId: Number,
                    comments: string,
                    commentsSubmitHandler: Function,
                    stores: string[]
                  } ) => {

    const readOnlyState = useSelector(
        (state: RootState) => state.readOnly
    )

    const [isEditing, setIsEditing] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const [commentsEditValue, setcommentsEditValue] = useState(comments);

    const handleEditOnClick = () => {
        setcommentsEditValue(comments);
        setIsEditing(!isEditing);
    }

    const handleCommentsChange = (e: BaseSyntheticEvent) => {
        setcommentsEditValue(e.target.value);
    }

    const handleCommentsSubmit = () => {
        commentsSubmitHandler(priceChangeId, commentsEditValue, stores);
        setIsEditing(false);
    }

    const handleKeydown = (e: KeyboardEvent) => {
        switch (e.key) {
            case 'Escape': {
                setIsEditing(false);
                break;
            }
            case 'Enter': {
                handleCommentsSubmit();
                break;
            }
        }
    }

    const cellStyle: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };

    const editButtonStyle: React.CSSProperties = {
        display: readOnlyState.isReadOnly ? 'none' : isHovering ? 'block' : 'none',
    }

    const inputStyle: React.CSSProperties = {
        width: '75%',
        marginRight: '5%',
    }

    const editJSX = <>
        <textarea style={inputStyle}
            value={commentsEditValue}
            placeholder="Enter comments here"
            autoFocus
            onChange={ handleCommentsChange }
            onKeyDown={ handleKeydown } />
        <Button title="Cancel" icon={<CloseOutlined />} onClick={ handleEditOnClick } />
        <Button title="Save" icon={<CheckOutlined />} onClick={ handleCommentsSubmit } />
    </>

    const commentsJSX = <>
        {comments ? (<p style={{margin:'5px 0px'}}>{comments}</p>) : (<p style={{height:'4px'}}></p>)}
        <div>
            <Button title="Click to edit the comments"
                    style={editButtonStyle}
                    icon={<EditOutlined />}onClick={ handleEditOnClick }
            />
        </div>
    </>


    return (
        <div style={cellStyle} onMouseEnter={() => { setIsHovering(true) }} onMouseLeave={() => { setIsHovering(false)}}>
            {isEditing ? editJSX : commentsJSX }
        </div>
    );
}

export default Comments;
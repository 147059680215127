import { FC } from "react";
import { Result, Button } from "antd";
import { useHistory } from "react-router-dom";

const PageNotFound: FC<{}> = () => {
  const history = useHistory();
  return (
    <>
      <Result
        status='404'
        title='404'
        subTitle='Sorry, the page you visited does not exist.'
        extra={
          
          <Button onClick={() => history.goBack()} type='primary'>
            This way please
          </Button>
          
        }
      />
    </>
  );
};

export default PageNotFound;

import { createEnrollAuthenticatorMeta } from "@okta/okta-auth-js";
import React from "react";
import { Wave } from "react-animated-text";

const Style = {
  display: "inline-block",
   marginBottom: "1em",
  padding: "1em 1em 1em 1m",
  width: "100%",
  align:"center",
  fontSize: "2.9rem"
};

export const Wave1 = () => (
  <div style={Style}>
    <Wave text="Promotions Login" effect="fadeOut" effectChange={1.0} />
  </div>
);
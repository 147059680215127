import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import appConfig from "../../config/config.json";
import { AppThunk } from "../reduxStore";
import { findPriceChangeURL } from "../../utils/utils";
import { getSignKitColors } from "../../utils/api/pricechange.service";
import { IServiceURL } from "../../interfaces/serviceURL.interface";
import { ISignKitColors } from "../../interfaces/signKitColors.interface";

interface getSignKitColorsState {
  colors: ISignKitColors;
  isLoading: boolean;
  error: string | null;
}

const initialState: getSignKitColorsState = {
  colors: {DoorbusterKit: '#FFFFFF', Generic: '#FFFFFF', GenericAltColor: '#FFFFFF', PDF: '#FFFFFF'},
  isLoading: false,
  error: null,
};

const SignKitColorsSlice = createSlice({
  name: "signKitColors",
  initialState,
  reducers: {
    getSignKitColorsStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getSignKitColorsSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.colors = action.payload;
      state.error = null;
    },
    getSignKitColorsFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.colors = {DoorbusterKit: '#FFFFFF', Generic: '#FFFFFF', GenericAltColor: '#FFFFFF', PDF: '#FFFFFF'};
      state.error = action.payload;
    },
  },
});

export default SignKitColorsSlice.reducer;
const {
  getSignKitColorsStart,
  getSignKitColorsSuccess,
  getSignKitColorsFailed,
} = SignKitColorsSlice.actions;

export const fetchSignKitColors = (serviceURLs: IServiceURL[]): AppThunk => async (dispatch) => {
  try {
    dispatch(getSignKitColorsStart());
  
    if (!serviceURLs.length) return;

    // Fetch price changes from backend
    const priceChangeAPI = findPriceChangeURL(serviceURLs, appConfig.stage); // TODO: to pull env specific when CI is integrated.
    if (!priceChangeAPI) {
    throw new Error("Could not find Price Change API.")
    }
    const response = await getSignKitColors(priceChangeAPI) as any;

    if (response.ok) {
      const data = await response.json();
      const result = data.reduce((acc, item) => {
        const noSpacesName = item.name.replace(/\s+/g, '');
        acc[noSpacesName] = item.color;
        return acc;
      }, {});
      dispatch(getSignKitColorsSuccess(result))
    }

  } catch (error) {
    console.log("ERROR fetching stores: ", error);
    dispatch(getSignKitColorsFailed(error));
  }
};
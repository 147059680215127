import './App.css';
import { Layout } from "antd";
import { Component, ReactNode } from 'react';
import { BrowserRouter as Router, Route, withRouter, RouteComponentProps, Switch } from 'react-router-dom';
import PageNotFound from "../src/components/error/PageNotFound";
import HomePage from "../src/components/home/Home";
import Promotions from "./components/home/Promotions";
import AppHeader from "../src/components/header/AppHeader";
import { SecureRoute, Security, LoginCallback } from "@okta/okta-react"
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import LoginHome from '../src/components/login/LoginHome';
import Profile from '../src/components/login/Profile';
import appConfig from "./config/config.json";
import { Provider } from "react-redux";
import reduxStore from './reduxStore/reduxStore';

const { Footer } = Layout;

const oktaAuth = new OktaAuth({
  issuer: appConfig.oktaURL,
  clientId: appConfig.clientId,
  redirectUri: window.location.origin + '/'
});

interface IState {
  store: string;
  effectiveDate: Date;
}

class App extends Component<RouteComponentProps, IState> {
  constructor(props) {
    super(props);
    this.state = { store: "", effectiveDate: new Date() };
    this.restoreOriginalUri = async (_oktaAuth, originalUri) => {
      props.history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };
  }

  storeChange = (store: string) => {
    console.log(`The store changed: ${store}`);
    this.setState({ store })
  }

  effectiveDateChange = (date: Date) => {
    this.setState({ effectiveDate: date});
  }

  restoreOriginalUri = async (oktaAuth: any, originalUri: string) => {
    this.props.history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  render(): ReactNode {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Security oktaAuth={oktaAuth} restoreOriginalUri={this.restoreOriginalUri}>
          {this.props.location.pathname !== '/login' && <AppHeader store={this.state.store} effectiveDate={this.state.effectiveDate} effectiveDateChange={this.effectiveDateChange} storeChange={this.storeChange} />}
          <Route exact path="/login" component={LoginHome} />
          <Route path="/" component={LoginCallback} />

          <Switch>
            <SecureRoute exact path="/" render={(props) => <HomePage {...props} store={this.state.store} effectiveDate={this.state.effectiveDate} />} />
            <SecureRoute exact path="/promotions" render={() => <Promotions effectiveDate={this.state.effectiveDate} />} />
            <SecureRoute exact path="/profile" component={Profile} />
            {this.props.location.pathname !== '/login' && <SecureRoute path="*" render={(props) => props.location.pathname !== '/' && <PageNotFound />} />}
          </Switch>
        </Security>
        {this.props.location.pathname !== '/login' && <Footer style={{ textAlign: "right" }}>Copyright &copy; Carter's Inc. All Rights Reserved</Footer>}
      </Layout>
    )
  }
}

const AppWithRouterAccess = withRouter(App);

class RouterApp extends Component {
  render() {
    return (
      <Provider store={reduxStore}>
        <Router>
          <AppWithRouterAccess />
        </Router>
      </Provider>
    );
  }
}

export default RouterApp;

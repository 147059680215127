import { useState, useEffect } from "react";
import { DatePicker, Space } from "antd";
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

interface IEffectiveDateChange { (date: Date): void; }

const EffectiveDate = ({ effectiveDateChange, effectiveDate, validEffectiveDates }: { effectiveDateChange: IEffectiveDateChange, effectiveDate: Date, validEffectiveDates: string[] }) => {

    const onDatePicked = (date, dateString) => {
        effectiveDateChange(date.toDate()); // antd uses dayjs for their date objects
    };

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        return current && validEffectiveDates.indexOf(current.format('MM/DD/YYYY')) === -1
    };

    return (
        <DatePicker style={{height: '32px', marginLeft: '20px' }}disabledDate={disabledDate} allowClear={false} onChange={onDatePicked} value={dayjs(effectiveDate)} />
    )
};

export default EffectiveDate;